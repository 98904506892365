<template>
  <v-dialog
    v-model="show"
    @keydown.esc="$emit(`close`)"
    fullscreen
    hide-overlay
    height="100%"
    scrollable
    transition="dialog-bottom-transition"
    persistent
    no-click-animation
  >
    <v-card
      class="elevation-0 ma-auto"
      tile
      width="1400"
      :max-width="$vuetify.breakpoint.mobile ? '95%' : '70%'"
    >
      <div class="pl-16 ml-5 pr-10">
        <OnboardingProgressBar />
      </div>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            absolute
            left
            color="white"
            elevation="0"
            class="mt-3"
            v-bind="attrs"
            v-on="on"
            @click="cancel"
          >
            <v-icon v-text="'mdi-close'" color="secondary lighten-2" />
          </v-btn>
        </template>
        <span class="text-capitalize">{{ $t("action.close") }}</span>
      </v-tooltip>

      <v-window v-model="onboardingStep" touchless style="width: 100%">
        <v-window-item :value="1">
          <StartedOnboarding
            @started="(onboardingStep = 2), setOnboardingStep(2)"
          />
        </v-window-item>

        <v-window-item :value="2">
          <CreateUser
            @back="previousStep"
            v-if="!created || selectUser"
            @userCreated="created = true"
            @userSelected="userSelected()"
          />
          <UserInstructions
            @backToCreate="backToCreate"
            @next="nextStep"
            v-else
          />
        </v-window-item>

        <v-window-item :value="3">
          <UpdateUserSignatureInfo @back="previousStep" @next="nextStep" />
        </v-window-item>

        <v-window-item :value="4">
          <UpdateUser @back="previousStep" @next="nextStep" />
        </v-window-item>

        <v-window-item :value="5">
          <UpdateUserApplySignature @back="previousStep" @next="nextStep" />
        </v-window-item>

        <v-window-item :value="6">
          <AddShift @back="previousStep" @next="nextStep" />
        </v-window-item>

        <v-window-item :value="7">
          <!-- TODO: trocar setOnboardingStep(7) por nextStep quando tiver o proximo step -->
          <EnterpriseResources
            @back="previousStep"
            @next="setOnboardingStep(8)"
          />
        </v-window-item>

        <v-window-item :value="8">
          <ConcludedOnboardingVue @back="previousStep" @goHome="goHome" />
        </v-window-item>
      </v-window>
    </v-card>
  </v-dialog>
</template>

<script>
import OnboardingProgressBar from "@/components/onboardingNewUser/OnboardingProgressBar.vue";
import StartedOnboarding from "@/components/onboardingNewUser/StartedOnboarding.vue";
import CreateUser from "@/components/onboardingNewUser/CreateUser.vue";
import UpdateUserSignatureInfo from "@/components/onboardingNewUser/UpdateUserSignatureInfo.vue";
import UpdateUserApplySignature from "@/components/onboardingNewUser/UpdateUserApplySignature.vue";
import AddShift from "@/components/onboardingNewUser/AddShift.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { setFirebaseEvent } from "@/helpers/services/registerAnalytics.js";
import UpdateUser from "@/components/onboardingNewUser/UpdateUser.vue";
import ConcludedOnboardingVue from "@/components/onboardingNewUser/ConcludedOnboarding.vue";
import EnterpriseResources from "@/components/onboardingNewUser/EnterpriseResources.vue";
import UserInstructions from "@/components/onboardingNewUser/UserInstructions.vue";
export default {
  name: "OnboardingNewUser",
  components: {
    OnboardingProgressBar,
    StartedOnboarding,
    CreateUser,
    UpdateUserSignatureInfo,
    UpdateUserApplySignature,
    UpdateUser,
    AddShift,
    ConcludedOnboardingVue,
    EnterpriseResources,
    UserInstructions,
  },
  data() {
    return {
      created: false,
      steps: 0,
      show: true,
      rules: {
        equalPasswords: (value) =>
          value === this.userPassword.password ||
          this.$ml.get("passwords_do_not_match"),
        min_len: (v) =>
          v.length >= this.minimumSize ||
          `${this.$ml.get("min_len_label")} ${this.minimumSize}`,
        has_upper: (v) =>
          /[A-Z]/.test(v) || this.$ml.get("must_have_a_upper_letter"),
        has_lower: (v) =>
          /[a-z]/.test(v) || this.$ml.get("must_have_a_lower_letter"),
        has_number: (v) => /\d/.test(v) || this.$ml.get("must_have_a_number"),
        //eslint-disable-next-line
        has_symbol: (v) =>
          /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(v) ||
          this.$ml.get("must_have_a_symbol"),
      },
      passwordStrengthRules: [],
      hasPasswordError: true,
      selectedDomain: "",
      tab: null,
      next: null,
      domainSelected: null,
      hasSelectedDomain: [(v) => !!v || this.$ml.get("select_a_domain")],
      userId: null,
    };
  },
  computed: {
    ...mapGetters(["currentOnboardingStep", "company", "userById"]),
    onboardingStep: {
      get() {
        return this.currentOnboardingStep;
      },
      set(newStep) {
        this.setOnboardingStep(newStep);
      },
    },

    selectUser() {
      return localStorage.getItem("userSelected");
    },

    stepsNames() {
      return {
        1: "welcome",
        2: "create_user",
        3: "signature_informations",
        4: "user_informations",
        5: "apply_signature",
        6: "shifts_control",
        7: "enterprise_resources",
        8: "conclusion",
      };
    },
    mainDomain() {
      return this.company.main_domain;
    },
  },
  methods: {
    ...mapMutations(["setOnboardingStep", "setSnackBar"]),
    ...mapActions(["getUserById"]),
    setFirebaseEvent,
    saveAnalytics({ event, click }) {
      if (this.mainDomain) {
        this.setFirebaseEvent(this.mainDomain, event, click);
      }
    },
    cancel() {
      this.$router.push({ name: "Home" });
      const log = {
        event: `cancel_onboarding_on_step_${
          this.stepsNames[this.currentOnboardingStep]
        }`,
        click:
          "Botão de fechar no canto superior esquerdo do fluxo de onboarding",
      };
      this.saveAnalytics(log);
    },
    previousStep() {
      this.onboardingStep = this.onboardingStep - 1;
      this.setOnboardingStep(this.onboardingStep);
      if (this.onboardingStep == 2 && this.selectUser)
        localStorage.removeItem("userSelected");

      const log = {
        event: `returned_onboarding_to_step_${
          this.stepsNames[this.currentOnboardingStep]
        }`,
        click:
          "Botão de voltar no canto superior esquerdo do fluxo de onboarding",
      };
      this.saveAnalytics(log);
    },
    backToCreate() {
      this.removeStorageData();
      const log = {
        event: "returned_to_create_user_onboarding",
        click: "Botão de voltar para criação de usuário",
      };
      this.saveAnalytics(log);
    },
    nextStep() {
      const log = {
        event: `next_onboarding_step_${
          this.stepsNames[this.currentOnboardingStep]
        }`,
        click: "Botão de avançar para o próximo step no fluxo de onboarding",
      };
      this.saveAnalytics(log);
      this.setOnboardingStep(this.currentOnboardingStep + 1);
    },
    removeStorageData() {
      localStorage.removeItem("userId");
      localStorage.removeItem("userCreated");
      localStorage.removeItem("userSelected");
      localStorage.removeItem("formSignatureInfoData");
      localStorage.removeItem("userGroups");
      this.created = false;
    },

    resetAll() {
      this.removeStorageData();
      this.setOnboardingStep();
    },

    goHome() {
      this.$router.push({ name: "Home" });
      setTimeout(this.resetAll, 500);
      const log = {
        event: "finish_onboarding",
        click:
          "Botão de voltar para home no último step do fluxo de onboarding",
      };
      this.saveAnalytics(log);
    },
    async tryToGetUser() {
      if (!this.userById.id_google && this.userId) {
        await this.getUserById(this.userId);
        setTimeout(() => {
          setTimeout(this.tryToGetUser(), 5000);
        }, 5000);
      }
    },
    userSelected() {
      if (localStorage.getItem("userSelected")) {
        this.tryToGetUser();
        if (this.currentOnboardingStep == 2) this.nextStep();
      }
    },
  },
  watch: {
    created() {
      if (localStorage.getItem("userCreated")) {
        this.tryToGetUser();
      }
    },
  },
  beforeMount() {
    if (localStorage.getItem("onboardingStep")) {
      this.onboardingStep = JSON.parse(localStorage.getItem("onboardingStep"));
    }
    if (localStorage.getItem("userCreated")) {
      this.created = true;
    } else {
      this.userSelected();
    }

    this.userId = JSON.parse(localStorage.getItem("userId"));
  },
};
</script>

<style></style>

<template>
  <v-container fluid class="d-flex flex-column fill-height">
    <v-card
      v-if="loaded"
      tile
      flat
      class="elevation-0 overflow-y-auto ma-auto ml-10 mr-10"
      height="calc(89vh - 80px)"
      max-width="90vw"
    >
      <alert
        class="mt-2"
        dense
        color="warning"
        v-if="!userById.is_gmail_enabled"
      >
        <div class="d-flex flex-inline align-center justify-space-between">
          <span>{{ $t("common.inactiveUserInWorkspaceAlert") }}</span>

          <div :class="`${$vuetify.breakpoint.smAndDown ? '' : 'd-flex'}`">
            <v-btn
              text
              small
              color="secondary lighten-2"
              class="mr-2 text-body-2 font-weight-medium"
              @click="
                redirectToGoogleUserInWorkspace(userById.id_google, 'licenses')
              "
              :loading="localLoading"
            >
              {{ $t("action.assignLicense") }}
              <v-icon right v-text="'mdi-open-in-new'" class="mr-3" size="20" />
            </v-btn>
            <v-btn
              text
              small
              outlined
              color="warning"
              :class="`ml-auto text-body-2 font-weight-medium ${
                $vuetify.breakpoint.smAndDown ? 'mt-2' : ''
              }`"
              @click="checkUserById()"
              :loading="localLoading"
            >
              {{ $t("action.reloadUserInfo") }}
              <v-icon right v-text="'mdi-reload'" class="mr-3" size="20" />
            </v-btn>
          </div>
        </div>
      </alert>
      <v-card-title class="pa-2 ml-16">
        {{ $t("onboardingNewUser.companyCalendars") }}
      </v-card-title>
      <v-row style="width: 89vw" class="ma-0 pa-0" justify="center">
        <v-col
          :cols="`${$vuetify.breakpoint.mdAndUp ? '11' : '12'}`"
          class="mt-0"
        >
          <UserCalendar :isPanelOpened="true" outlined :user="userById" />
        </v-col>
      </v-row>
      <v-card-title class="pa-2 ml-16">
        {{ $t("common.companyDrives") }}
      </v-card-title>
      <v-row style="width: 89vw" class="ma-0 pa-0" justify="center">
        <v-col
          :cols="`${$vuetify.breakpoint.mdAndUp ? '11' : '12'}`"
          class="mt-0"
        >
          <UserSharedDrives :isPanelOpened="true" outlined :user="userById" />
        </v-col>
      </v-row>
    </v-card>

    <v-card
      v-else
      tile
      flat
      class="elevation-0 overflow-y-auto ma-auto ml-10 mr-10"
      height="calc(89vh - 80px)"
      max-width="90vw"
    >
      <v-row class="ma-0 pa-0" style="width: 90vw">
        <v-col
          v-for="key in [1]"
          :key="key"
          class="pt-0"
          cols="12"
          sm="12"
          md="12"
          lg="12"
        >
          <v-progress-linear
            v-if="!loaded"
            indeterminate
            color="primary"
            class="mb-2"
          ></v-progress-linear>
          <div><v-skeleton-loader type="text" /></div>
          <div><v-skeleton-loader type="image" /></div>
        </v-col>
      </v-row>
    </v-card>
    <v-divider style="width: 90vw"></v-divider>
    <v-footer
      rounded
      style="height: 70px"
      color="background"
      class="border-bottom rounded-xl mt-3"
      width="90vw"
    >
      <v-btn
        text
        height="52"
        color="secondary"
        style="border: 2px solid grey !important"
        class="mx-auto text-none text-body-1 font-weight-medium px-8"
        outlined
        :loading="loading"
        @click="$emit('back')"
      >
        <v-icon right v-text="'mdi-arrow-left'" class="mr-4" size="22" />
        {{ $t("common.back") }}
      </v-btn>

      <v-spacer />

      <v-btn
        height="52"
        color="accent"
        class="mx-auto text-none text-body-1 font-weight-bold px-8 mr-0"
        @click="$emit('next')"
        :loading="loading"
      >
        {{ $t("action.next") }}
        <v-icon right v-text="'mdi-arrow-right'" class="ml-4" size="22" />
      </v-btn>
    </v-footer>
  </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import UserCalendar from "../user/UserCalendar.vue";
import UserSharedDrives from "../user/UserSharedDrives.vue";
import { redirectToGoogleUserInWorkspace } from "@/helpers/services/users";
export default {
  name: "EnterpriseResources",
  components: {
    UserCalendar,
    UserSharedDrives,
  },

  props: {},

  data() {
    return {
      loading: false,
      localLoading: false,
    };
  },

  computed: {
    ...mapGetters([
      "token",
      "userById",
      "mainDomain",
      "allCalendars",
      "companySharedDrives",
    ]),

    loaded() {
      return !this.loading && this.userById?.id_google;
    },
  },

  methods: {
    ...mapMutations(["setSnackBar"]),
    ...mapActions(["getUserById", "getCalendars"]),
    async checkUserById() {
      const id =
        this.userById.id_google || JSON.parse(localStorage.getItem("userId"));
      this.localLoading = true;
      await this.getUserById(id).finally(() => {
        this.localLoading = false;
      });
    },

    redirectToGoogleUserInWorkspace,
  },
  async beforeMount() {
    if (!this.allCalendars.length && this.userById.is_gmail_enabled) {
      this.getCalendars();
    }
    const user =
      localStorage.getItem("userCreated") ||
      localStorage.getItem("userSelected");
    if (
      (!this.userById?.email && this.userById.email !== user.email) ||
      !this.userById?.is_gmail_enabled
    ) {
      await this.checkUserById();
    }
  },
};
</script>
<style scoped></style>

<template>
  <HeaderDialog
    v-if="show"
    id="confirm_apply_signature"
    :color="primaryColor"
    title="Aplicar assinatura"
    :show="show"
    :persistent="false"
    :loading="loadingApplySignature"
    width="700"
    @close="reset"
    @action="applyTemplate"
    closeText="Cancelar"
    button-class="text-none text-subtitle-1 font-weight-medium elevation-0"
    :disabledAction="!scheduleDateSelected && scheduleOption"
  >
    <template v-slot:body>
      <v-card-text class="text-body-1 pa-0 mb-5 mx-0 pt-3">
        Os usuários selecionados terão sua assinatura modificada.
        <strong> Deseja continuar? </strong>
      </v-card-text>

      <Alert :prominent="false" color="warning" dense :width="800" class="mx-0">
        {{ $t("applySignature.messageDelayToApply") }}
      </Alert>

      <v-checkbox
        v-model="notifyUsers"
        class="mt-5 mx-0 mb-0"
        :disabled="loadingApplySignature"
        :loading="loadingApplySignature"
      >
        <template v-slot:label>
          <div class="d-flex align-center text-body-2">
            Notificar alteração de assinatura para todos os usuários
            <v-tooltip top max-width="300">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="info"
                  v-bind="attrs"
                  v-on="on"
                  size="18"
                  class="ml-1"
                  v-text="'mdi-help-circle-outline'"
                />
              </template>
              <span>
                Usuários restritos receberão o e-mail de solicitação da
                aplicação de assinatura independente dessa opção ser selecionada
                ou não.
              </span>
            </v-tooltip>
          </div>
        </template>
      </v-checkbox>
      <v-checkbox
        v-model="scheduleOption"
        class="mt-0 mx-0"
        :disabled="loadingApplySignature"
        :loading="loadingApplySignature"
      >
        <template v-slot:label>
          <div class="d-flex align-center text-body-2">
            Realizar agendamento da aplicação da assinatura
            <v-tooltip top max-width="300">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="info"
                  v-bind="attrs"
                  v-on="on"
                  size="18"
                  class="ml-1"
                  v-text="'mdi-help-circle-outline'"
                />
              </template>
              <span>
                A aplicação da assinatura somente será realizada na data
                selecionada no campo abaixo e possui um limite de 30 dias.
              </span>
            </v-tooltip>
          </div>
        </template>
      </v-checkbox>
      <v-menu
        v-if="scheduleOption"
        v-model="menuScheduleDate"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        min-width="auto"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            append-icon="mdi-calendar"
            :value="scheduleDate"
            label="Escolher data para aplicação"
            hint="Limite de 30 dias a partir de hoje"
            :placeholder="scheduleDateLabel"
            readonly
            hide-details
            outlined
            v-bind="attrs"
            v-on="on"
            :color="primaryColor"
            :disabled="loadingApplySignature"
            :loading="loadingApplySignature"
          />
        </template>
        <v-date-picker
          v-model="scheduleDateSelected"
          :min="addDaysFormat(1)"
          :max="addDaysFormat(30)"
          :color="primaryColor"
          @input="menuScheduleDate = false"
          :disabled="loadingApplySignature"
          :loading="loadingApplySignature"
        >
          <v-spacer></v-spacer>
        </v-date-picker>
      </v-menu>
    </template>
  </HeaderDialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  errorMessages,
  successMessages,
} from "@/helpers/variables/snackbarMessages";
import { addDaysFormat, formatDate } from "@/helpers/services/utils";

export default {
  name: "ConfirmSignApplyDialog",

  props: {
    show: { type: Boolean, default: () => false },
    user: { type: Object, default: () => ({}) },
    template: { type: Object, default: () => ({}) },
    emails: { type: Object, default: () => ({}) },
  },

  data() {
    return {
      loadingApplySignature: false,
      scheduleDateSelected: "",
      menuScheduleDate: false,
      scheduleOption: false,
      notifyUsers: false,
    };
  },

  watch: {
    scheduleOption() {
      if (!this.scheduleOption) {
        this.scheduleDateSelected = "";
      }
    },
  },

  methods: {
    ...mapActions(["setSignatureToUsers", "completeMission"]),

    ...mapMutations(["setSnackBar", "setLoading"]),

    addDaysFormat,
    formatDate,

    reset() {
      this.$emit("show", false);
      this.scheduleDateSelected = "";
      this.scheduleOption = false;
      this.notifyUsers = false;
    },

    async applyTemplate() {
      this.loadingApplySignature = true;
      this.setLoading(true);
      const payload = {
        emails: this.emails,
        send_as: true,
        notify_users: this.notifyUsers,
        template_key: this.template.id,
      };
      if (this.scheduleDateSelected) {
        payload.schedule_date = this.scheduleDateSelected;
      }

      await this.setSignatureToUsers(payload)
        .then(() => {
          if (this.scheduleDate) {
            this.setSnackBar({
              message: `O agendamento foi feito para: ${this.scheduleDate}. Acompanhe os agendamentos em 'Aplicação automática'.`,
              show: true,
              color: "success",
            });
          } else {
            this.setSnackBar({
              message: successMessages.set_signature_to_users,
              show: true,
              color: "success",
            });
          }
          this.completeMission("apply_email_signature");

          this.$emit("finish", payload);
          this.$emit("show", false);
        })
        .catch((error) => {
          this.setSnackBar({
            message: errorMessages.set_signature_to_users,
            show: true,
            color: "error",
          });
          console.error("applyTemplate(): ", error);
        })
        .finally(() => {
          this.loadingApplySignature = false;
          this.setLoading();
          this.reset();
        });
    },
  },

  computed: {
    ...mapGetters(["primaryColor", "secondaryColor"]),

    scheduleDateLabel() {
      if (this.$vuetify.breakpoint.xs) {
        return "Data de agendamento";
      }
      return "Escolher data de agendamento de aplicação";
    },

    scheduleDate() {
      if (!this.scheduleDateSelected) {
        return null;
      }
      return formatDate(this.scheduleDateSelected);
    },
  },
};
</script>

<template>
  <v-container fluid class="d-flex flex-column fill-height">
    <div @click="showPermissionProductDialog">
      <v-card outlined max-width="1400" class="mx-auto rounded-lg" flat>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="controlShifts"
          item-key="key"
          :search="search"
          show-select
          checkbox-color="accent"
          fixed-header
          :sort-by="['name']"
          :loading="loadingGroups"
          @toggle-select-all="getPageSelection"
          :class="`${$vuetify.theme.dark ? 'background' : ''} elevation-0`"
          :style="`${
            $vuetify.theme.dark
              ? 'border-bottom: 1px solid #424242 !important'
              : ''
          }`"
          :items-per-page="itemsPerPage"
          :footer-props="{
            itemsPerPageOptions: itemsOptions,
            itemsPerPageText: $t('common.linesPerPage'),
          }"
          :height="tableHeight"
          mobile-breakpoint="0"
          :no-data-text="$t('common.noDataAvailable')"
        >
          <template
            v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }"
          >
            {{ `${pageStart}-${pageStop}` }}
            {{ `${$t("common.of")} ${itemsLength}` }}
          </template>
          <template v-slot:top>
            <v-row
              v-if="hasSelectedGroups"
              :class="`${
                $vuetify.theme.dark ? 'grey darken-2' : 'orange lighten-5'
              } d-flex align-center px-2 my-0 py-0 ma-0`"
              style="border-radius: 7px 7px 0px 0px"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mr-2"
                    icon
                    @click="clearSelectedGroups()"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon v-text="'mdi-close'" />
                  </v-btn>
                </template>
                <span class="caption">{{
                  $t("onboardingNewUser.resetSelection")
                }}</span>
              </v-tooltip>
              <v-card-title>
                {{ selectedGroupsNumber }}
                {{ $t("onboardingNewUser.selectedShifts") }}
              </v-card-title>
            </v-row>

            <v-row
              v-else
              :class="`${
                $vuetify.breakpoint.xs
                  ? 'd-flex flex-column'
                  : 'd-flex align-center flex-wrap'
              } mx-2 my-0 py-0`"
            >
              <v-col
                lg="5"
                md="8"
                sm="8"
                xs="12"
                :class="`${
                  $vuetify.breakpoint.xs ? '' : 'd-flex align-center'
                } py-0 px-0`"
              >
                <v-card-title>
                  {{ $t("onboardingNewUser.shifts") }}
                </v-card-title>
                <v-divider
                  vertical
                  class="my-6"
                  v-if="!$vuetify.breakpoint.xs"
                />
                <v-card-subtitle
                  :class="`
                ${
                  $vuetify.theme.dark
                    ? 'grey--text text--lighten-5'
                    : 'grey--text text--darken-2'
                }
                text-body-1
              `"
                >
                  {{ $t("onboardingNewUser.showingAllShifts") }}
                </v-card-subtitle>
              </v-col>
            </v-row>
            <v-divider />
            <v-row
              v-if="allSelectedAreInTheSamePage || allSelected"
              :class="`${
                $vuetify.theme.dark ? 'grey darken-2' : 'grey lighten-3'
              } d-flex align-center justify-center px-2 my-0 py-2 ma-0 text-body-2`"
              :style="$vuetify.breakpoint.mdAndUp ? 'height: 40px' : ''"
            >
              <!-- TEXTO QUE APARECE QUANDO TODOS OS ITEMS DA PÁGINA ESTÃO SELECIONADOS -->
              <div
                v-if="allSelectedAreInTheSamePage && !allSelected"
                class="d-flex align-center text-wrap"
              >
                {{ $t("onboardingNewUser.all") }}
                <span class="font-weight-medium mx-1">{{
                  selectedGroupsNumber
                }}</span>
                {{ $t("onboardingNewUser.shiftsSelectedsInPage") }}
                <v-btn
                  text
                  x-small
                  color="info darken-2"
                  :block="$vuetify.breakpoint.sm"
                  class="text-none font-weight-medium text-body-2 px-1 py-0 my-0"
                  @click="selected = allGroups.slice()"
                >
                  {{ $t("onboardingNewUser.selectAllShifts") }}
                </v-btn>
              </div>

              <!-- TEXTO QUE APARECE QUANDO TODOS OS ITENS DA TABELA ESTÃO SELECIONADOS -->
              <div v-if="allSelected" class="d-flex align-center text-wrap">
                {{ $t("onboardingNewUser.all") }}
                <span class="font-weight-medium mx-1">{{
                  selectedGroupsNumber
                }}</span>
                {{ $t("onboardingNewUser.shiftsSelectedsInTable") }}
                <v-btn
                  text
                  x-small
                  color="info darken-2"
                  class="text-none font-weight-medium text-body-2 px-1 py-0 my-0"
                  @click="clearSelectedGroups()"
                >
                  {{ $t("onboardingNewUser.clearSelection") }}
                </v-btn>
              </div>
            </v-row>
            <v-divider v-if="allSelectedAreInTheSamePage || allSelected" />
            <v-row style="max-height: 72px" class="px-0">
              <v-text-field
                v-model="search"
                :placeholder="$t('onboardingNewUser.searchShift')"
                class="elevation-0 mx-1 mt-4 mb-0 transparent"
                background-color="transparent"
                filled
                dense
                rounded
                height="25"
                full-width
                persistent-placeholder
              >
                <template v-slot:prepend-inner>
                  <v-icon v-text="'mdi-magnify'" class="my-0 mr-4" left />
                </template>
              </v-text-field>
            </v-row>
            <v-divider />
          </template>
          <template v-slot:loading> {{ $t("common.loading") }} </template>
          <template v-slot:no-results> {{ $t("common.noResults") }} </template>

          <!-- INÍCIO: HEADERS -->
          <template v-slot:[`header.name`]="{}">
            {{ $t("common.name") }}
          </template>

          <template v-slot:[`header.is_active`]="{}"> Status </template>
          <template v-slot:[`header.users.length`]="{}">
            {{ $t("onboardingNewUser.participants") }}
          </template>

          <template v-slot:[`header.days_period_to_expire_password`]="{}">
            {{ $t("onboardingNewUser.passwordPolicy") }}
            <v-tooltip top max-width="300">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="grey darken-1"
                  v-bind="attrs"
                  v-on="on"
                  small
                  v-text="'mdi-help-circle'"
                />
              </template>
              <span>
                {{ $t("onboardingNewUser.passwordPolicyTooltipText") }}
              </span>
            </v-tooltip>
          </template>

          <template v-slot:[`header.pontomais_login_rule`]="{}">
            {{ $t("onboardingNewUser.login") }}
            <v-tooltip top max-width="300">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="grey darken-1"
                  v-bind="attrs"
                  v-on="on"
                  small
                  v-text="'mdi-help-circle'"
                />
              </template>
              <span>
                {{ $t("onboardingNewUser.loginTooltipText") }}
              </span>
            </v-tooltip>
          </template>

          <template v-slot:[`header.auto_logout`]="{}">
            {{ $t("onboardingNewUser.logout") }}
            <v-tooltip top max-width="300">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="grey darken-1"
                  v-bind="attrs"
                  v-on="on"
                  small
                  v-text="'mdi-help-circle'"
                />
              </template>
              <span>
                {{ $t("onboardingNewUser.logoutTooltipText") }}
              </span>
            </v-tooltip>
          </template>

          <template v-slot:[`item.name`]="{ item }">
            <span class="blue--text text--darken-2 link">
              {{ item.name }}
            </span>
          </template>
          <template v-slot:[`item.is_active`]="{ item }">
            <span class="d-flex align-center">
              <v-icon
                class="mr-1"
                :color="item.is_active ? 'success' : 'error'"
                size="20"
              >
                {{
                  item.is_active
                    ? "mdi-check-circle-outline"
                    : "mdi-close-circle-outline"
                }}
              </v-icon>
              {{ item.is_active ? "Ativo" : "inativo" }}
            </span>
          </template>
          <template v-slot:[`item.pontomais_login_rule`]="{ item }">
            <span class="d-flex align-center">
              {{
                item.pontomais_login_rule
                  ? $t("onboardingNewUser.pointControl")
                  : "-"
              }}
            </span>
          </template>
          <template v-slot:[`item.auto_logout`]="{ item }">
            <span class="d-flex align-center">
              {{ getLogoutText(item) }}
            </span>
          </template>
          <template v-slot:[`item.days_period_to_expire_password`]="{ item }">
            <v-tooltip top max-width="300">
              <template v-slot:activator="{ on: tooltip }">
                <span
                  v-if="item.days_period_to_expire_password"
                  class="d-flex align-center"
                  v-on="{ ...tooltip }"
                >
                  {{
                    item.days_period_to_expire_password
                      ? `${$t("onboardingNewUser.changeEvery")} ${
                          item.days_period_to_expire_password
                        } ${$t("onboardingNewUser.days")}`
                      : "-"
                  }}
                </span>
                <span v-else class="d-flex align-center">-</span>
              </template>
              <span>
                {{ $t("onboardingNewUser.nextPasswordChange") }}
                {{ formatDate(item.date_to_expire_password) }}</span
              >
            </v-tooltip>
          </template>
        </v-data-table>
        <v-snackbar v-model="showSnackbarAllSelected" bottom>
          {{ $t("onboardingNewUser.allShiftsSelected") }}
          <template v-slot:action="{ attrs }">
            <v-btn
              dark
              ext
              v-bind="attrs"
              @click="showSnackbarAllSelected = false"
            >
              {{ $t("action.close") }}
            </v-btn>
          </template>
        </v-snackbar>
      </v-card>
    </div>
    <v-divider style="width: 90vw"></v-divider>
    <v-footer
      rounded
      style="height: 70px"
      color="background"
      class="border-bottom rounded-xl mt-3"
      width="90vw"
    >
      <v-btn
        text
        height="52"
        color="secondary"
        style="border: 2px solid grey !important"
        class="mx-auto text-none text-body-1 font-weight-medium px-8"
        outlined
        @click="$emit('back')"
        :disabled="loading"
      >
        <v-icon right v-text="'mdi-arrow-left'" class="mr-4" size="22" />
        {{ $t("common.back") }}
      </v-btn>

      <v-spacer />
      <div>
        <v-btn
          text
          height="52"
          color="secondary"
          style="border: 2px solid grey !important"
          outlined
          class="mx-auto text-none text-body-1 font-weight-medium px-8 mr-5"
          @click="$emit('next')"
          :disabled="loading"
        >
          {{ $t("action.skip") }}
        </v-btn>

        <v-btn
          height="52"
          color="accent"
          class="mx-auto text-none text-body-1 font-weight-bold px-8 mr-0"
          @click="save()"
          :loading="loading"
          :disabled="!hasSelectedGroups || !hasControlPermission"
        >
          {{ $t("action.applyAndNext") }}
          <v-icon right v-text="'mdi-arrow-right'" class="ml-4" size="22" />
        </v-btn>
      </div>
    </v-footer>
  </v-container>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { paging } from "@/helpers/variables/tablesDefault";
import { formatDate } from "@/helpers/services/utils";
import { errorMessages } from "@/helpers/variables/snackbarMessages";

export default {
  name: "AddShift",

  props: {},

  data() {
    return {
      loading: false,
      loadingGroups: false,
      /* COPIED */
      currentGroups: [],
      groupKey: "",
      groupName: "",
      showSnackbarAllSelected: false,
      showEditGroup: false,
      showCreateGroup: false,
      selected: [],
      filters: [],
      currentFilters: {
        is_active: [],
        weekday: [],
      },
      search: "",
      currentAction: "",
      allItemsInThePage: [],
      itemsPerPage: paging.perPage,
      itemsOptions: paging.All,
      tableHeight: "calc(90vh - 290px)",
      showConfirmDialog: false,
      actionDialog: "",
      actionName: "",
      confirmationReversible: false,
      alertMessage: {
        show: false,
        message: "",
        type: "",
      },
    };
  },

  computed: {
    ...mapGetters([
      "token",
      "userById",
      "controlShifts",
      "companyHasControl",
      "company",
    ]),

    hasSelectedGroups() {
      return this.selected.length > 0;
    },

    selectedGroupsNumber() {
      return this.selected.length;
    },

    allSelectedAreInTheSamePage() {
      if (this.selected.length > 0) {
        return (
          this.selected.every((item) =>
            this.allItemsInThePage.includes(item)
          ) && this.allItemsInThePage.length === this.selected.length
        );
      } else {
        return false;
      }
    },

    allSelected() {
      if (this.selected.length == 0) {
        return false;
      }

      return this.allGroups.length === this.selected.length;
    },

    allGroups() {
      if (this.isFiltering) {
        let groups = this.controlShifts.reduce((acc, group) => {
          if (
            Object.keys(this.currentFilters).map((key) => {
              if (this.verifyFilter(group, key)) {
                return group;
              }
            })
          ) {
            acc.push(group);
          }
          return acc;
        }, []);
        return groups;
      }
      return this.controlShifts;
    },

    isFiltering() {
      return this.filters.length !== 0;
    },

    headers() {
      return [
        {
          align: "start",
          sortable: true,
          value: "name",
          width: 250,
        },
        {
          value: "is_active",
          width: 150,
        },
        {
          value: "users.length",
          sortable: false,
          width: 130,
        },
        {
          value: "pontomais_login_rule",
          width: 200,
        },
        {
          value: "auto_logout",
          width: 210,
        },
        {
          value: "days_period_to_expire_password",
          width: 210,
        },
      ];
    },

    hasControlPermission() {
      const not_valid_status = [
        "PLAN_TRIAL_ENDED",
        "PRODUCT_DOES_NOT_EXIST_IN_PLANS",
      ];
      if (not_valid_status.includes(this.companyHasControl)) return false;
      else return true;
    },
  },

  methods: {
    ...mapActions(["getControlShifts", "addUserToShifts"]),

    ...mapMutations(["setSnackBar", "setPermissionDialog"]),

    formatDate,

    clearSelectedGroups() {
      this.selected = [];
    },

    getLogoutText(item) {
      if (
        !item.advanced_auto_logout &&
        !item.auto_logout &&
        item.pontomais_auto_logout_rules.length == 0
      ) {
        return "-";
      } else {
        let text = "";
        if (item.auto_logout) {
          text += "Auto Logout";
        }
        if (item.advanced_auto_logout) {
          text += "Advanced Logout";
        }
        if (
          (item.auto_logout || item.advanced_auto_logout) &&
          item.pontomais_auto_logout_rules.length > 0
        ) {
          text += " / VR Gente | PontoMais Logout";
        } else if (item.pontomais_auto_logout_rules.length > 0) {
          text += "VR Gente | PontoMais Logout";
        }
        return text.trim();
      }
    },

    getPageSelection(event) {
      if (event.value) {
        this.allItemsInThePage = event.items;
      }
    },

    async save() {
      this.setLoading(true);
      const rule_groups = this.selected.map((group) => group.key);

      const payload = {
        rule_groups,
        email: this.userById.email,
      };

      this.addUserToShifts(payload)
        .then(() => {
          this.setSnackBar({
            show: true,
            message: `${this.userById.name} ${this.$t(
              "common.userAddedToShifts"
            )}`,
            color: "success",
          });

          this.$emit("next");
        })
        .catch((error) => {
          console.error(error);
          const { data } = error.response;

          const didNotFindUserInControl = data.code == "USERS_NOT_FOUND";

          const { USER_NOT_FOUND_IN_CONTROL, unknown } = errorMessages;

          this.setSnackBar({
            show: true,
            message: didNotFindUserInControl
              ? USER_NOT_FOUND_IN_CONTROL
              : unknown,
            color: "error",
          });
        })
        .finally(() => {
          this.setLoading();
        });
    },

    setLoading(load = false) {
      this.loading = load;
      this.loadingGroups = load;
    },

    showPermissionProductDialog() {
      if (this.hasControlPermission) return null;
      const dialog = {
        show: true,
        status: "PRODUCT_IN_DISABLED_PLAN",
        app: "conecta_control",
      };
      this.setPermissionDialog(dialog);
    },
  },

  watch: {
    allSelected() {
      if (this.allSelected && !this.isFiltering) {
        this.showSnackbarAllSelected = true;
      } else {
        this.allItemsInThePage = [];
        this.selected = [];
      }
    },
  },

  async beforeMount() {
    if (!this.hasControlPermission) {
      this.showPermissionProductDialog();
      return;
    }

    if (this.controlShifts.length) {
      return;
    }

    this.setLoading(true);
    await this.getControlShifts().finally(() => {
      this.setLoading();
    });
  },
};
</script>
